import Vue from "vue";
import VueMeta from 'vue-meta';
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

Vue.use(VueMeta, {
  keyName: 'head'
});

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
