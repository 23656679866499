<template>
  <div id="app">
    <div class="header">
      <v-container>
        <v-row>
          <div class="col-sm-4 col-10">
            <router-link to="/" class="logo">
              <h1>
                <v-icon>
                  mdi-clock-time-five-outline
                </v-icon>
                Exchange<span>Hours</span>
              </h1>
            </router-link>
          </div>
          <div class="col-sm-8 col-2">
            <div class="float-right menu">
              <v-btn icon @click.stop="drawer = !drawer">
                <v-icon dark>mdi-menu</v-icon>
              </v-btn>
              <v-navigation-drawer
                v-model="drawer"
                absolute
                bottom
                temporary
                right
              >
                <v-list>
                  <v-list-item v-for="(item, i) in nav" :key="i">
                    <v-list-item-title>
                      <div class="text-left">
                        <router-link :to="{ path: item.link }">
                          {{ item.title }}
                        </router-link>
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-navigation-drawer>
            </div>
          </div>
        </v-row>
      </v-container>
    </div>
    <div class="main">
      <v-container>
        <router-view />
      </v-container>
    </div>
    <div class="footer">
      <v-container>
        &copy; {{ currentYear }} ExchangeHours. All rights reserved
        <div class="float-right">
          Made by
          <a target="_blank" rel="noopener" href="https://www.nedvajz.cz/"
            >Nedvajz</a
          >
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
var currentYear = new Date().getFullYear();

export default {
  name: "App",
  data: function() {
    return {
      drawer: null,
      currentYear: currentYear,
      nav: [
        {
          title: "Stock",
          link: "/"
        },
        {
          title: "Cryptocurrency",
          link: "/cryptocurrency"
        },
        {
          title: "FAQ",
          link: "/faq"
        }
      ]
    };
  }
};
</script>

<style lang="scss">
@import "~@/scss/style.scss";
</style>
