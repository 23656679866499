import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Stock",
    component: () => import("../views/Stock.vue"),
    meta: {
      title: "Stock exchanges trading hours",
      metaTags: [
        {
          name: "description",
          content:
            "Trading hours of top 20 stock exchanges NYSE, NASDAQ, JPX, LSE, SSE, Euronext, FRA and others. Public holidays included. Possible to go to eToro at the moment, other traders will come later."
        }
      ]
    }
  },
  {
    path: "/cryptocurrency",
    name: "Cryptocurrency",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Cryptocurrency.vue"),
    meta: {
      title: "Cryptocurrency exchanges trading hours",
      metaTags: [
        {
          name: "description",
          content:
            "Trading hours of top 20 cryptocurrency exchanges Huobi, Binance, Bybit, BitMEX, BitZ, FTX, OKEx, Bitget and others. Direct link to let you trade next to each exchange."
        }
      ]
    }
  },
  {
    path: "/commodity",
    name: "Commodity",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Commodity.vue")
  },
  {
    path: "/faq",
    name: "FAQ",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import("../views/Faq.vue"),
    meta: {
      title: "FAQ for exchangehours.com",
      metaTags: [
        {
          name: "description",
          content:
            "The FAQ page including answers re trading hours methodics, checked exchanges and available traders."
        }
      ]
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);

  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.metaTags);

  if (nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else {
    document.title = previousNearestWithMeta.meta.title;
  }

  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map(el => el.parentNode.removeChild(el));

  if (!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags
    .map(tagDef => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach(key => {
        tag.setAttribute(key, tagDef[key]);
      });

      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;
